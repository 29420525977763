import React from 'react'
import { Link, useIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import * as styles from './our-partners.module.scss'
import partnersSample from '../../../data-sample/partners.sample.json'
import PropTypes from 'prop-types'

export default function OurPartners(props) {
  const intl = useIntl()
  return (
    <section className={classNames(styles.ourPartners, 'page-section')}>
      <div className="container">
        <h2 className="section-title title-oblique-line">
          {intl.formatMessage({ id: 'our-partners.title' })}
        </h2>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <Swiper
              spaceBetween={20}
              slidesPerView={2.5}
              breakpoints={{
                640: {
                  slidesPerView: 3.4,
                },
                1240: {
                  slidesPerView: 5,
                },
              }}
            >
              {partnersSample.map(partner => (
                <SwiperSlide key={partner.id}>
                  <div className={styles.partner} title={partner.name}>
                    <img
                      src={partner.image.url}
                      alt={partner.name}
                      className={styles.partnerLogo}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="text-center py-2 mt-3">
          <Link
            to={props.seeMoreLink}
            className={classNames('btn btn-outline-primary block-on-sm')}
          >
            {intl.formatMessage({ id: 'our-partners.discover-more' })}
          </Link>
        </div>
      </div>
    </section>
  )
}

OurPartners.propTypes = {
  seeMoreLink: PropTypes.string,
}
