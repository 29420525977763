import React from 'react'
import PropTypes from 'prop-types'
import Service from '../../common/Services/Service'
import { useIntl, Link } from 'gatsby-plugin-intl'
import get from 'lodash/get'
import classNames from 'classnames'
import * as styles from './home-banner.module.scss'
import useLink from '../../../hooks/useLink'

export default function HomeBanner(props) {
  const intl = useIntl()
  const { buildURL } = useLink()

  return (
    <div className={styles.homeBanner}>
      <div>
        <video
          className={styles.videoBackground}
          muted
          loop
          autoPlay
          playsInline=""
          width="640"
          height="480"
        >
          <source src={'/assets/videos/intro.mp4'} type="video/mp4" />
        </video>
      </div>
      <div className="d-flex flex-column justify-content-between min-vh-100 container text-center position-relative">
        <div className="m-auto">
          <h1 className={classNames(styles.intro, 'text-white')}>{props.description}</h1>
          {props.buttonLink && (
            <Link
              to={props.buttonLink}
              className={classNames('btn btn-outline-light', styles.inBriefBtn)}
            >
              {intl.formatMessage({ id: 'home.beig3-in-brief' })}
            </Link>
          )}
        </div>
        <div className="row small-row">
          {props.services.map(service => (
            <div className="col-6 col-md-4 col-lg-3" key={service.slug}>
              <Service
                icon={get(service, 'image.file.url')}
                title={service.title}
                color={service.color}
                to={buildURL(service)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

HomeBanner.propTypes = {
  description: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
  buttonLink: PropTypes.string,
}
