import React from 'react'
import { Link, useIntl } from 'gatsby-plugin-intl'
import { Swiper, SwiperSlide } from 'swiper/react'
import PropTypes from 'prop-types'
import 'swiper/css'
import Card from '../../common/Card/Card'
import useLink from '../../../hooks/useLink'

export default function OurProjects(props) {
  const intl = useIntl()
  const { buildRootURL, buildURL } = useLink()

  return (
    <section className={'overflow-hidden page-section'}>
      <div className="container">
        <h2 className={'text-primary section-title title-oblique-line'}>
          {intl.formatMessage({ id: 'our-projects.title' })}
        </h2>
        <Swiper
          className={'overflow-visible mb-4'}
          spaceBetween={10}
          slidesPerView={1}
          breakpoints={{
            512: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {props.projets.map(project => (
            <SwiperSlide key={project.id} className={'h-auto'}>
              <Card to={buildURL(project)} {...project} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="text-center mt-md-5">
          <Link
            to={buildRootURL({ ...props.projets[0], category: undefined, type: undefined })}
            className={'btn btn-outline-primary block-on-sm'}
          >
            {intl.formatMessage({ id: 'our-projects.see-more' })}
          </Link>
        </div>
      </div>
    </section>
  )
}

OurProjects.propTypes = {
  projets: PropTypes.array,
}
