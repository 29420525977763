import React from 'react'
import PropTypes from 'prop-types'
import { Link, useIntl } from 'gatsby-plugin-intl'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import EventCard from '../../common/EventCard/EventCard'
import useLink from '../../../hooks/useLink'

export default function OurEvents(props) {
  const intl = useIntl()
  const { buildURL, buildRootURL } = useLink()

  return (
    <div
      style={{
        backgroundColor: '#3f2e1e',
        backgroundImage:
          'url("https://images.unsplash.com/photo-1501908734255-16579c18c25f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1528&q=80")',
      }}
      className="page-section with-overlay"
    >
      <div className="overlay" />
      <div className="content">
        <div className={'container'}>
          <h2 className={'text-primary section-title title-oblique-line'}>
            {intl.formatMessage({ id: 'our-events.title' })}
          </h2>
          <Swiper
            className={'overflow-visible mb-4'}
            spaceBetween={10}
            slidesPerView={1}
            breakpoints={{
              512: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {props.events.map(event => (
              <SwiperSlide key={event.id} className={'h-auto'}>
                <EventCard {...event} to={buildURL(event)} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="text-center">
            <Link
              to={buildRootURL({ ...props.events[0], type: undefined })}
              className={'btn btn-outline-light block-on-sm'}
            >
              {intl.formatMessage({ id: 'our-events.see-more' })}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

OurEvents.propTypes = {
  events: PropTypes.array,
}
