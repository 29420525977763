import React, {} from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import HomeLayout from '../components/layouts/HomeLayout'
import Seo from '../components/seo'
import HomeBanner from '../components/sections/HomeBanner/HomeBanner'
import OurProjects from '../components/sections/OurProjects/OurProjects'
import News from '../components/sections/News/News'
import OurEvents from '../components/sections/OurEvents/OurEvents'
import OurSolutions from '../components/sections/OurSolutions/OurSolutions'
import OurPartners from '../components/sections/OurPartners/OurPartners'
import OurLocations from '../components/sections/OurLocations/OurLocations'
import Map from '../components/sections/Map/Map'
import useLink from '../hooks/useLink'

const PageLandingTemplate = props => {
  const location = typeof window !== 'undefined' ? window.location : {pathname: ''}
  const data = get(props, 'data')
  const page = get(data, 'contentfulPageLanding')
  const allContentfulPage = get(data, 'allContentfulPage.edges', [])
  const allContentfulPageEvenement = get(data, 'allContentfulPageEvenement.edges', []).map(page => page.node)
  const projets = allContentfulPage.filter(service => service.node.pageType === 'Projet').map(service => service.node)
  const news = allContentfulPage.filter(service => service.node.pageType === 'Actualité').map(service => service.node)
  const solutions = allContentfulPage.filter(service => service.node.pageType === 'Référence').map(service => service.node)
  const allLocations = get(data, 'allContentfulWidgetLocation.edges', []).map(location => location.node)
  const { buildURL } = useLink()

  return (
    <HomeLayout location={location}>
      <Seo title={page.title} />
      <HomeBanner description={page.description.description} services={page.widgets} buttonLink={buildURL(page.inBriefPage)} />
      {projets?.length > 0 && <OurProjects projets={projets} />}
      {allLocations?.length > 0 && <OurLocations locations={allLocations} />}
      {news?.length > 0 && <News news={news} />}
      {allContentfulPageEvenement?.length > 0 && <OurEvents events={allContentfulPageEvenement} />}
      {solutions?.length > 0 && <OurSolutions solutions={solutions} />}
      <OurPartners seeMoreLink={buildURL(page.partnersPage)} />
      <Map />
    </HomeLayout>
  )
}

export default PageLandingTemplate

export const pageQuery = graphql`
query HomeQuery($locale: String!) {
  contentfulPageLanding {
    __typename
    title
    slug
    description {
      description
    }
    widgets {
      __typename
        id
        title
        slug
        category
        pageType
        color
        updatedAt
        description {
          description
        }
        image {
          title
          file {
            url
          }
        }
    }
    inBriefPage {
      ...PageBasicInformation
    }
    partnersPage {
      ...PageBasicInformation
    }
  }
  allContentfulPageEvenement(filter: {node_locale: {eq: $locale}}) {
    edges {
      node {
        __typename
        id
        slug
        type
        node_locale
        startDate
        endDate
        title
        image {
          title
          file {
            url
          }
        }
      }
    }
  }
  allContentfulPage(filter: {node_locale: {eq: $locale}}) {
    edges {
      node {
        __typename
        id
        title
        slug
        category
        pageType
        color
        updatedAt
        description {
          description
        }
        image {
          title
          file {
            url
          }
        }
      }
    }
  }
  allContentfulWidgetLocation(filter: {node_locale: {eq: $locale}}) {
    edges {
      node {
        description {
          raw
        }
        slug
        title
        type
        images {
          file {
            url
            fileName
          }
        }
      }
    }
  }
}
`