import React from 'react'
import PropTypes from 'prop-types'
import { Link, useIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import Card from '../../common/Card/Card'
import useLink from '../../../hooks/useLink'

const News = props => {
  const intl = useIntl()
  const { buildURL, buildRootURL } = useLink()

  return (
    <section className={'overflow-hidden page-section'}>
      <div className="container">
        <h2 className="text-primary section-title title-oblique-line">
          {intl.formatMessage({ id: 'news.title' })}
        </h2>
        <div className="row">
          {props.news.map((news, index) => (
            <div
              className={classNames(['col-12', 'mb-3'], { 'col-lg-4': index > 0 })}
              key={news.id}
            >
              <Card {...news} to={buildURL(news)} horizontal={index === 0} />
            </div>
          ))}
        </div>
        <div className="text-center mt-md-5">
          <Link
            to={buildRootURL({ ...props.news[0], category: undefined, type: undefined })}
            className={'btn btn-outline-primary block-on-sm'}
          >
            {intl.formatMessage({ id: 'news.see-more' })}
          </Link>
        </div>
      </div>
    </section>
  )
}

News.propTypes = {
  news: PropTypes.array,
}

export default News
