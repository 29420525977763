import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import 'leaflet-defaulticon-compatibility'
import data from './data.js'
import { useIntl } from 'gatsby-plugin-intl'

const Map = () => {
  const MAPBOX_API_KEY = process.env.GATSBY_MAPBOX_API_KEY
  const intl = useIntl()
  const isBrowser = typeof window !== 'undefined'

  return (
    <section className="pt-5">
      <div className='container'>
        <h2 className="text-primary section-title title-oblique-line">
          {intl.formatMessage({ id: 'map.title' })}
        </h2>
      </div>
      <div style={{ height: '500px' }}>
        {isBrowser && (
          <MapContainer
            center={[7.36965, 12.344586]}
            zoom={6}
            scrollWheelZoom={false}
            style={{ height: '100%', width: '100%' }}
          >
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_API_KEY}`}
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
            />
            {data.map(pin => (
              <Marker key={pin.name} position={pin.position} draggable={false} animate={true}>
                <Popup>{pin.name}</Popup>
              </Marker>
            ))}
          </MapContainer>
        )}
      </div>
    </section>
  )
}

export default Map
