import React from 'react'
import PropTypes from 'prop-types'
import { Link, useIntl } from 'gatsby-plugin-intl'
import CardSlider from '../../common/CardSlider/CardSlider'
import Card from '../../common/Card/Card'
import useLink from '../../../hooks/useLink'

const OurSolutions = props => {
  const intl = useIntl()
  const { buildURL, buildRootURL } = useLink()

  return (
    <section className="container page-section">
      <h2 className="text-primary section-title title-oblique-line">
        {intl.formatMessage({ id: 'solutions.title' })}
      </h2>
      <CardSlider
        cards={props.solutions.map(solution => (
          <Card key={solution.slug} {...solution} to={buildURL(solution)} />
        ))}
      />
      <div className="text-center pt-5">
        <Link
          to={buildRootURL(props.solutions[0])}
          className={'btn btn-outline-primary block-on-sm'}
        >
          {intl.formatMessage({ id: 'our-solutions.see-more' })}
        </Link>
      </div>
    </section>
  )
}

OurSolutions.propTypes = {
  solutions: PropTypes.array,
}

export default OurSolutions
