import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Card from '../../common/Card/Card'

export default function OurLocations(props) {
  const intl = useIntl()
  return (
    <section className={'overflow-hidden page-section'}>
      <div className="container">
        <h2 className={'text-primary section-title title-oblique-line'}>
          {intl.formatMessage({ id: 'our-locations.title' })}
        </h2>
        <div className='row'>
          {props.locations?.map((location, index) => (
            <div key={index} className='col-sm-12 col-md-6 col-lg-3 mb-4'>
              <Card
                noLink
                pageType={location?.type}
                title={location?.title}
                image={location?.images?.length >= 2 ? location?.images : location?.images[0]}
                description={{ description: documentToReactComponents(JSON.parse(location.description.raw)) }} />
            </div>))
          }
        </div>
      </div>
    </section>
  )
}

OurLocations.propTypes = {
  locations: PropTypes.array,
}
