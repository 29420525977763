// extracted by mini-css-extract-plugin
export var card = "event-card-module--card--6UbsF";
export var imageWrapper = "event-card-module--image-wrapper--QaHJX";
export var cardHeader = "event-card-module--card-header--6PU0g";
export var cardHeaderDates = "event-card-module--card-header-dates--mXigD";
export var cardHeaderLocation = "event-card-module--card-header-location--+HBKy";
export var cardBodyType = "event-card-module--card-body-type--8lXPA";
export var cardHeaderDatesDay = "event-card-module--card-header-dates-day--bfjw9";
export var cardHeaderDatesMonth = "event-card-module--card-header-dates-month--uZx5n";
export var cardHeaderDatesYear = "event-card-module--card-header-dates-year--OZyKS";
export var cardHeaderDatesSeparator = "event-card-module--card-header-dates-separator--Er6rC";
export var cardBody = "event-card-module--card-body--+J0Ol";
export var cardBodyTitle = "event-card-module--card-body-title--9xQDQ";