import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './service.module.scss'
import { Link } from 'gatsby-plugin-intl'

export default function Service(props) {
  return (
    <Link to={props.to} className={styles.service} style={{ color: props.color }}>
      <div className={styles.serviceContent}>
        <img src={props.icon} alt={props.title} className={styles.serviceImage} />
        <h5 className={styles.serviceTitle}>{props.title}</h5>
      </div>
    </Link>
  )
}

Service.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}
