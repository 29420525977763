import React from 'react'
import PropTypes from 'prop-types'
import { Link, useIntl } from 'gatsby-plugin-intl'
import * as styles from './event-card.module.scss'

function DateDisplay({ date }) {
  const intl = useIntl()
  return (
    <div>
      <div className={styles.cardHeaderDatesDay}>{intl.formatDate(date, { day: 'numeric' })}</div>
      <div className={styles.cardHeaderDatesMonth}>{intl.formatDate(date, { month: 'short' })}</div>
      <div className={styles.cardHeaderDatesYear}>{intl.formatDate(date, { year: 'numeric' })}</div>
    </div>
  )
}

DateDisplay.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

export default function EventCard(props) {
  const endsTheSameDay = props.startDate === props.endDate

  return (
    <Link className={styles.card} to={props.to}>
      <picture className={styles.imageWrapper}>
        <img src={props.image.file.url} alt={props.image.title || props.title} />
      </picture>
      <header className={styles.cardHeader}>
        <div className={styles.cardHeaderDates}>
          <DateDisplay date={props.startDate} />
          {!endsTheSameDay && <span className={styles.cardHeaderDatesSeparator}>-</span>}
          {!endsTheSameDay && <DateDisplay date={props.endDate} /> }
        </div>
        <span className={styles.cardHeaderLocation}>{props.location}</span>
      </header>
      <div className={styles.cardBody}>
        <h6 className={styles.cardBodyType}>{props.type}</h6>
        <span className={styles.cardBodyTitle}>{props.title}</span>
      </div>
    </Link>
  )
}

EventCard.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  to: PropTypes.string.isRequired,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
}
