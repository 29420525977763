export default [
  {
    position: ['9.311667', '13.876667'],
    name: 'BIBEMI',
  },
  {
    position: ['9.878611', '14.104167'],
    name: 'WAPOUZÉ',
  },
  {
    position: ['3.415278', '11.619722'],
    name: 'SO\'O',
  },
  {
    position: ['2.05', '15.016111'],
    name: 'SONGODIBA',
  },
  {
    position: ['2.353611', '13.278889'],
    name: 'LELE',
  },
  {
    position: ['2.290556', '14.015'],
    name: 'DJADON',
  },
  {
    position: ['2.446944', '10.535'],
    name: 'BINGA',
  },
  {
    position: ['4.015278', '10.238333'],
    name: 'SANAGA',
  },
  {
    position: ['3.210833', '13.976389'],
    name: 'NKAMOUNA',
  },
  {
    position: ['6.636389', '12.218611'],
    name: 'TIMI',
  },
  {
    position: ['6.636389', '11.998889'],
    name: 'MBAM',
  },
  {
    position: ['6.701667', '12.361389'],
    name: 'TIBATI',
  },
  {
    position: ['4.241389', '11.647222'],
    name: 'BATCHENGA',
  },
  {
    position: ['4.854722', '12.910556'],
    name: 'NSEN',
  },
  {
    position: ['4.778056', '12.735'],
    name: 'BIBE',
  },
  {
    position: ['4.405556', '11.998889'],
    name: 'MBANDJOCK',
  },
  {
    position: ['4.296111', '11.845'],
    name: 'FANNY',
  },
  {
    position: ['2.793889', '14.338889'],
    name: 'NGATO',
  },
  {
    position: ['7.751944', '13.269444'],
    name: 'TENEKOU',
  },
  {
    position: ['7.847222', '13.600833'],
    name: 'MBE',
  },
  {
    position: ['7.709722', '13.518333'],
    name: 'NDOM',
  },
  {
    position: ['7.575', '13.266944'],
    name: 'NIAMBARAN',
  },
  {
    position: ['7.685278', '14.257222'],
    name: 'POKOR',
  },
  {
    position: ['7.809444', '12.852778'],
    name: 'NANA',
  },
  {
    position: ['7.918333', '13.039444'],
    name: 'DOGON',
  },
  {
    position: ['7.771389', '13.039444'],
    name: 'SANGA',
  },
]
